import styled from "styled-components";

import {
	COLOR_TEXT_PRIMARY_B2U,
	FONT_WT_HEAVY,
	FONT_WT_LIGHT,
	TABLET_BP,
	MOBILE_L_BP,
} from "@website-builder/utilities-index";

export const Card = styled.div`
	margin: 30px auto;
	text-align: center;
	@media only screen and (max-width: ${TABLET_BP}px) {
		width: 100px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		width: 100%;
	}
`;

export const Logo = styled.div`
	height: 36px;
	margin-top: 11px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
export const Image = styled.div`
	display: inline-block;
	height: 97px;
	width: 97px;
	border-radius: 50%;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
`;
export const Title = styled.div`
	font-weight: ${FONT_WT_HEAVY};
	line-height: 22px;
	margin-top: 15px;
	text-align: center;
`;
export const SubTitile = styled.div`
	font-weight: ${FONT_WT_LIGHT};
	line-height: 22px;
	color: ${COLOR_TEXT_PRIMARY_B2U};
	margin-top: 2px;
	text-align: center;
`;
