import React from "react";
import { Card, Logo, Image, Title, SubTitile } from "./styles";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";

const MentorCard = ({ avatar, name, designation, companyLogo }) => {
	return (
		<Card>
			<Image>
				<CloudinaryImage
					url={avatar?.image}
					quality="auto"
					fetchFormat="auto"
					sizes={{
						default: {
							height: 100,
						},
					}}
					alt={avatar?.altTag}
				/>
			</Image>
			<Title>{name}</Title>
			<SubTitile>{designation}</SubTitile>
			<Logo
				data-bg={companyLogo?.image}
				className="lazyload"
				role="img"
				aria-label={companyLogo?.altTag}
			></Logo>
		</Card>
	);
};

export default MentorCard;
